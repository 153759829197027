@import "../shared";

.Message {
    @include popup;
    @include bigText;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    > div {
        padding: 0 20px;
        > h3 {
            padding-bottom: 1em;
            font-size: 1.2em;
            font-weight: bolder;
            text-decoration: underline;
        }
    }
}