@import "../shared";

.Auth {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;

    .Idle {
        @include center;
        z-index: 2;
    }
    footer, nav {
        left: 20px;
        right: 20px;
    }
    header {
        position: absolute;
        z-index: 2;
        top: 0;
        width: 100%;
        color: #fff;
        text-align: center;
        font-size: 50px;
        padding: 5px 0;
    }

    footer {
        position: absolute;
        z-index: 2;
        top: 10vh;
        text-align: center;

        button {
            @include btn;
        }
    }
    .bg {
        @include fullscreen;
    }
    nav {
        position: absolute;
        z-index: 2;
        bottom: 10vh;
        text-align: center;
        @include container;
        
        input {
            @include input;
        }
        button {
            @include btn;
            width: 100%;
        }
    }
}