@import "../shared";

.Gate {
    @include popup;
    @include bigText;

    > .content, > .bg {
        @include fullscreen;
    }
    > .content {
        z-index: 2;

        > header, > footer {
            padding: 0 20px;
        }

        > header {
            text-align: center;
            > img {
                max-height: 10vh;
            }
        }
        > p {
        }
        > footer {
            position: absolute;
            z-index: 2;
            bottom: 2vh;
            left: 0;
            width: 100%;
            text-align: center;
            
            input {
                @include input;
            }

            button {
                @include btn(#fff, rgb(0, 163, 0));
                width: 100%;
            
                &[disabled] {
                    @include btn(#eee, #ccc);
                }
            }
            nav {
                margin-top: 1em;
                button {
                    @include btn(#fff, #ff0000);
                }
            }
        }

        > header, > p, > footer div {
            @include container;
        }
    }
}