.mattone {
    width: 57px;
    height: 58px;
    background: url("ground.png") -58px -0px;
	background-size: 285px 285px;
}

.lastre {
    width: 58px;
    height: 58px;
    background: url("ground.png") -0px -0px;
	background-size: 285px 285px;
}

.pietra3 {
    width: 57px;
    height: 58px;
    background: url("ground.png") -115px -0px;
	background-size: 285px 285px;
}

.terra {
    width: 58px;
    height: 57px;
    background: url("ground.png") -0px -58px;
	background-size: 285px 285px;
}

.pietra2 {
    width: 58px;
    height: 57px;
    background: url("ground.png") -58px -58px;
	background-size: 285px 285px;
}

.sassi {
    width: 58px;
    height: 57px;
    background: url("ground.png") -0px -115px;
	background-size: 285px 285px;
}

.piastrelle {
    width: 56px;
    height: 58px;
    background: url("ground.png") -172px -0px;
	background-size: 285px 285px;
}

.acqua {
    width: 57px;
    height: 57px;
    background: url("ground.png") -58px -115px;
	background-size: 285px 285px;
}

.roccia {
    width: 57px;
    height: 57px;
    background: url("ground.png") -115px -115px;
	background-size: 285px 285px;
}

.lava2 {
    width: 57px;
    height: 57px;
    background: url("ground.png") -228px -0px;
	background-size: 285px 285px;
}

.ghiaccio {
    width: 56px;
    height: 57px;
    background: url("ground.png") -228px -172px;
	background-size: 285px 285px;
}

.muro1 {
    width: 57px;
    height: 57px;
    background: url("ground.png") -228px -114px;
	background-size: 285px 285px;
}

.erba {
    width: 57px;
    height: 57px;
    background: url("ground.png") -0px -172px;
	background-size: 285px 285px;
}

.lastre2 {
    width: 57px;
    height: 57px;
    background: url("ground.png") -57px -172px;
	background-size: 285px 285px;
}

.pietra {
    width: 57px;
    height: 57px;
    background: url("ground.png") -114px -172px;
	background-size: 285px 285px;
}

.erba2 {
    width: 57px;
    height: 57px;
    background: url("ground.png") -171px -172px;
	background-size: 285px 285px;
}

.muro2 {
    width: 56px;
    height: 57px;
    background: url("ground.png") -172px -58px;
	background-size: 285px 285px;
}

.lava {
    width: 56px;
    height: 57px;
    background: url("ground.png") -172px -115px;
	background-size: 285px 285px;
}

.calanco {
    width: 56px;
    height: 57px;
    background: url("ground.png") -116px -58px;
	background-size: 285px 285px;
}

.boh {
    width: 57px;
    height: 57px;
    background: url("ground.png") -228px -57px;
	background-size: 285px 285px;
}

