.house2 {
    width: 80px;
    height: 80px;
    background: url("buildings.png") -80px -0px;
	background-size: 260px 260px;
}

.house {
    width: 80px;
    height: 80px;
    background: url("buildings.png") -0px -0px;
	background-size: 260px 260px;
}

.banquet {
    width: 80px;
    height: 80px;
    background: url("buildings.png") -0px -80px;
	background-size: 260px 260px;
}

.ship {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -80px -80px;
	background-size: 260px 260px;
}

.cuccia {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -160px -0px;
	background-size: 260px 260px;
}

.igloo {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -160px -50px;
	background-size: 260px 260px;
}

.mulino {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -160px -100px;
	background-size: 260px 260px;
}

.tree6 {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -210px -150px;
	background-size: 260px 260px;
}

.tent {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -50px -160px;
	background-size: 260px 260px;
}

.torre {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -100px -160px;
	background-size: 260px 260px;
}

.tree2 {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -150px -160px;
	background-size: 260px 260px;
}

.tree3 {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -210px -0px;
	background-size: 260px 260px;
}

.tree4 {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -210px -50px;
	background-size: 260px 260px;
}

.tree5 {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -210px -100px;
	background-size: 260px 260px;
}

.albero3 {
    width: 50px;
    height: 50px;
    background: url("buildings.png") -0px -160px;
	background-size: 260px 260px;
}

